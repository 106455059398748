import { Fragment, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Input from "./Input";
import '../styles/scrollbar.css';

const PersonalDetails = (props) => {
  const { company, formFields, loanData } = props;
  let fieldsState = {};
  let partnerFields = [];
  if (formFields && company) {
    partnerFields = formFields[company]?.filter(
      (field) => field?.page === "personal-details"
    );
    partnerFields?.forEach((field) => {
      if (!loanData) {
        return;
      }
      return (fieldsState[field.name] = loanData[field.name] || "");
    });
  }
  
  const formRef = useForm({
    defaultValues: fieldsState,
    mode: "onChange" // This enables real-time validation
  });
  
  const {
    reset,
    handleSubmit,
    trigger,
    formState: { errors, isDirty, isValid },
  } = formRef;

  const redirectToLoanDetailsPage = handleSubmit((data) => {
    props?.redirectToStep(data);
  });

  useEffect(() => {
    setTimeout(() => {
      reset(
        {
          ...fieldsState,
        },
        { keepDefaultValues: true }
      );
      trigger();
    }, 0);
  }, []);

  return (
    <>
      <div className="text-2xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
        Enter personal details
      </div>

      <FormProvider {...formRef}>
        <form
          onSubmit={(e) => e.preventDefault()}
          noValidate
          autoComplete="off"
          className="my-5 px-5"
        >
          <div className="max-h-[500px] custom-scrollbar">
            {partnerFields?.map((element, index) => (
              <Fragment key={`${element.name}-${index}`}>
                {element?.name && element?.name !== "terms_conditions" && (
                  <div className="mb-4 pr-2">
                    <Input {...element} />
                    {errors[element.name] && (
                      <div className="text-red-500 text-sm mt-1">
                        {errors[element.name]?.message || `${element.label} is required`}
                      </div>
                    )}
                  </div>
                )}
              </Fragment>
            ))}
            <div className="my-7 mx-5 flex items-center justify-center">
              <button
                onClick={redirectToLoanDetailsPage}
                disabled={!isDirty || !isValid}
                className="bg-[#4E05FF] text-white text-base py-2 px-3 rounded disabled:opacity-50"
              >
                Continue
              </button>
            </div>
				    <p className="text-sm text-gray-500">For any help please reach out to our support team on this number 022-41498149 </p>
            <p className="text-sm text-gray-500"> ಯಾವುದೇ ಸಹಾಯಕ್ಕಾಗಿ ದಯವಿಟ್ಟು ನಮ್ಮ ಸಹಾಯ ತಂಡವನ್ನು ಈ ಸಂಖ್ಯೆಯಲ್ಲಿ ಸಂಪರ್ಕಿಸಿ: 022-41498149 </p>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default PersonalDetails;
