import {
  getRequest,
  postRequest,
  deleteRequest,
  patchRequest,
} from "./apiService";

export async function sendOtpApiCallHandler(mobileNo) {
  try {
    const result = await postRequest("/loan-request", {
      type: "sendotp",
      mobile: mobileNo,
    });
    const { body, statusCode } = result?.data;
    if (statusCode === 200 && JSON.parse(body) === "success") {
      return "success";
    } else {
      return "failure";
    }
  } catch (err) {
    throw err;
  }
}

export async function verifyOtpApiCallHandler(mobileNo, otp) {
  try {
    const result = await postRequest("/loan-request", {
      type: "verifyotp",
      mobile: mobileNo,
      otp,
    });
    const { body, statusCode } = result?.data;
    if (statusCode === 200 && JSON.parse(body) === "success") {
      return "success";
    } else {
      return "failure";
    }
  } catch (err) {
    throw err;
  }
}

export async function fetchUserApiCallHandler(applicant_id) {
  try {
    const result = await postRequest("/loan-request", {
      type: "fetchuser",
      applicant_id: applicant_id,
    });
    const { body, statusCode } = result?.data;
    if (statusCode === 200 && JSON.parse(body) === "success") {
      return "success";
    } else {
      return "failure";
    }
  } catch (err) {
    throw err;
  }
}

export async function loanFormCheckStatusApiCallHandler(request) {
  try {
    const result = await postRequest("/form-precheck", request);
    const { status } = result;
    if (status === 200) {
      return { status: "success", ...result?.data };
    } else {
      return { status: "failure", ...result?.data };
    }
  } catch (err) {
    throw err;
  }
}

export async function sendLoanRequest(request) {
  try {
    const result = await postRequest("/loan-request", request);
    const { status, data } = result;
    if (status === 200 && data?.id) {
      return { status: "success", ...result?.data };
    } else {
      return { status: "failure", ...result?.data };
    }
  } catch (err) {
    throw err;
  }
}

export async function sendEshramRequest(request) {
  try {
    const result = await postRequest("/eshramRequestHandler", request);
    const { status, data } = result;
    console.log("eminem", result)
    if (status === 200 && data?.body?.status == "success") {
      return { status: "success", ...result?.data };
    } else {
      return { status: "failure", ...result?.data };
    }
  } catch (err) {
    throw err;
  }
}
