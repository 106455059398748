import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Loader from "./Loader";
import Input from "./Input";
import {
  sendOtpApiCallHandler,
  verifyOtpApiCallHandler,
} from "../service/loanService";
import { toast } from "react-toastify";

let initialState = {
  mobile_number: "",
  otp: "",
  whatsapp_number: "",
};
const mobile_number_config = {
  label: "Enter mobile number",
  type: "number",
  name: "mobile_number",
  id: "mobile_number",
  validation: {
    required: {
      value: true,
      message: "Mobile number is required",
    },
    maxLength: {
      value: 10,
      message: "Mobile number should be 10 digit",
    },
    minLength: {
      value: 10,
      message: "Mobile number should be 10 digit",
    },
  },
  page: "mobile-number-verification",
  inputInfoText:
    "After submission, we will be sending status updates on your whatsapp number",
};
const opt_config = {
  label: "Enter OTP",
  type: "number",
  name: "otp",
  id: "otp",
  validation: {
    required: {
      value: true,
      message: "OTP is required",
    },
    maxLength: {
      value: 4,
      message: "OTP should be 4 digit",
    },
    minLength: {
      value: 4,
      message: "OTP should be 4 digit",
    },
  },
  page: "mobile-number-verification",
  inputInfoText:
    "A verification code has been sent to your whatsapp registered mobile number.",
};
const whatsapp_number_config = {
  label: "WhatsApp Number",
  type: "number",
  name: "whatsapp_number",
  id: "whatsapp_number",
  validation: {
    required: {
      value: true,
      message: "WhatsApp number is required",
    },
    maxLength: {
      value: 10,
      message: "WhatsApp number should be 10 digit",
    },
    page: "mobile-number-verification",
    minLength: {
      value: 10,
      message: "WhatsApp number should be 10 digit",
    },
  },
};

const MobileVerification = (props) => {
  const formRef = useForm(initialState);
  const [isLoading, setLoader] = useState(false);
  const {
    handleSubmit,
    unregister,
    formState: { isDirty, isValid },
  } = formRef;
  const [optSent, setOtpSend] = useState(false);
  const [isThisMyWhatsAppNumber, setIsThisMyWhatsAppNumber] = useState(false);

  const sendOTPHandler = handleSubmit((data) => {
    const { mobile_number } = data;
    sendOtp(mobile_number);
  });

  const verifyOTPHandler = handleSubmit((data) => {
    const { mobile_number, otp } = data;
    verifyOtp(mobile_number, otp);
  });

  async function sendOtp(number) {
    try {
      setLoader(true);
      const result = await sendOtpApiCallHandler(`${number}`);
      if (result === "success") {
        toast.success("OTP sent to your mobile number.");
        window.gtag("event", "send_otp", { number });
        setOtpSend(true);
      }
    } catch (err) {
      toast.error("Something went wrong!");
    } finally {
      setLoader(false);
    }
  }

  async function verifyOtp(number, otp) {
    try {
      if (number && otp) {
        setLoader(true);
        const result = await verifyOtpApiCallHandler(`${number}`, otp);
        if (result === "success") {
          toast.success("OTP verified successfully.");
          window.gtag("event", "verify_otp_success", { number });
          props?.redirectToStep({ ...formRef?.getValues(), otp: "" });
        } else {
          toast.error("Incorrect OTP.");
          window.gtag("event", "verify_otp_failure", { number });
        }
      }
    } catch (err) {
      toast.error("Something went wrong!");
    } finally {
      setLoader(false);
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className="text-2xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
        Verify mobile number
      </div>
      <FormProvider {...formRef}>
        <form
          onSubmit={(e) => e.preventDefault()}
          noValidate
          autoComplete="off"
          className="my-5 px-5"
        >
          {!optSent && <Input {...mobile_number_config} />}
          {optSent && <Input {...opt_config} />}

          {optSent && (
            <span
              className="text-base text-[#4E05FF] font-sans my-5 border-[#5A786F] underline cursor-pointer"
              onClick={sendOTPHandler}
            >
              Resend OTP
            </span>
          )}

          {!optSent && (
            <div className="flex items-center my-6">
              <input
                checked={isThisMyWhatsAppNumber}
                id="default-checkbox"
                type="checkbox"
                value=""
                onChange={() => {
                  setIsThisMyWhatsAppNumber((prevState) => {
                    if (prevState) {
                      unregister(
                        whatsapp_number_config?.name,
                        whatsapp_number_config?.validation
                      );
                    }
                    return !prevState;
                  });
                }}
                className="w-4 h-4 text-[#5A786F] bg-gray-100 border-gray-300 rounded focus:ring-[5A786F] dark:focus:ring-[5A786F] dark:ring-offset-gray-800 focus:ring-2"
              />
              <label
                htmlFor="default-checkbox"
                className="ml-2 text-sm font-normal text-[#4D4D4D]"
              >
                This number is not my whatsapp number
              </label>
            </div>
          )}
          {!optSent && isThisMyWhatsAppNumber && (
            <Input {...whatsapp_number_config} />
          )}
          {!optSent && (
            <div className="my-7 mx-5 flex items-center justify-center">
              <button
                onClick={sendOTPHandler}
                disabled={!isDirty || !isValid}
                className="bg-[#4E05FF] text-white text-base py-2 px-3 rounded disabled:opacity-50"
              >
                Send OTP
              </button>
            </div>
          )}
          {optSent && (
            <div className="my-7 mx-5 flex items-center justify-center">
              <button
                onClick={verifyOTPHandler}
                disabled={!isDirty || !isValid}
                className="bg-[#4E05FF] text-white text-base py-2 px-3 rounded disabled:opacity-50"
              >
                Verify OTP
              </button>
            </div>
          )}
        </form>
      </FormProvider>
    </>
  );
};
export default MobileVerification;
