const EshramSuccessPage = ({ applicationId, company }) => {
	const getMessageByCompany = (company) => {
		switch (company) {
			case "ayushman":
				return {
					en: "Thank you for sharing all the documents with us. Our team will check your documents and reach out to you on your mobile number to verify the details and register for the schemes",
					kn: "ನಮಗೆ ಎಲ್ಲಾ ದಾಖಲೆಗಳನ್ನು ಹಂಚಿಕೊಟ್ಟಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ನಮ್ಮ ತಂಡವು ನಿಮ್ಮ ದಾಖಲೆಗಳನ್ನು ಪರಿಶೀಲಿಸಿ, ನಿಮ್ಮ ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸಲು ಮತ್ತು ಯೋಜನೆಗಳಿಗೆ ನೋಂದಾವಣೆ ಮಾಡಲು ನಿಮ್ಮ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಗೆ ಸಂಪರ್ಕಿಸುತ್ತದೆ."
				};
			case "eshram":
				return {
					en: "Thank you for sharing all the documents with us. Our team will check your documents and reach out to you on your mobile number to verify the details and register for the schemes",
					kn: "ನಮಗೆ ಎಲ್ಲಾ ದಾಖಲೆಗಳನ್ನು ಹಂಚಿಕೊಟ್ಟಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ನಮ್ಮ ತಂಡವು ನಿಮ್ಮ ದಾಖಲೆಗಳನ್ನು ಪರಿಶೀಲಿಸಿ, ನಿಮ್ಮ ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸಲು ಮತ್ತು ಯೋಜನೆಗಳಿಗೆ ನೋಂದಾವಣೆ ಮಾಡಲು ನಿಮ್ಮ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಗೆ ಸಂಪರ್ಕಿಸುತ್ತದೆ."
				};
			default:
				return {
					en: "Thank you for sharing your information. Our team will contact you for the next steps.",
					kn: "ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ಹಂಚಿಕೊಂಡಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ನಮ್ಮ ತಂಡವು ಮುಂದಿನ ಹಂತಗಳಿಗೆ ನಿಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸುತ್ತದೆ."
				};
		}
	};
	const message = getMessageByCompany(company);
	return (
		<>
			<div className="text-lg text-[#4D4D4D] font-sans font-bold my-7 px-5">
				<p className="text-base">{message.en}</p>
				<br />
				<p className="text-base">You will soon receive a message from our WhatsApp Channel - Sarvam (9301710643). Please save this number for future communication. You can also reach out to us on 022-41498149</p>
				<br />
				<p className="text-base">{message.kn}</p>
				<br />
				<p className="text-base">ಶೀಘ್ರದಲ್ಲೇ ನೀವು ನಮ್ಮ ವಾಟ್ಸಪ್ ಚಾನೆಲ್ - ಸರ್ವಂ (9301710643) ನಿಂದ ಸಂದೇಶವನ್ನು ಪಡೆಯುತ್ತೀರಿ. ದಯವಿಟ್ಟು ಭವಿಷ್ಯದಲ್ಲಿ ಸಂವಹನಕ್ಕಾಗಿ ಈ ಸಂಖ್ಯೆಯನ್ನು ಉಳಿಸಿಕೊಳ್ಳಿ. ನೀವು ನಮ್ಮನ್ನು 022-41498149 ನಂಬರಿನಲ್ಲೂ ಸಂಪರ್ಕಿಸಬಹುದು.</p>
			</div>
		</>
	);
};

export default EshramSuccessPage;
