import { useFormContext } from "react-hook-form";

const Input = (props) => {
  const { register, formState: { errors } } = useFormContext();
  const { label, type, name, validation, options, placeholder, inputInfoText } = props;

  return (
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2">
        {label}
      </label>
      
      {type === "select" ? (
        <select
          {...register(name, validation)}
          className={`w-full p-2 border rounded ${
            errors[name] ? 'border-red-500' : 'border-gray-300'
          }`}
        >
          <option value="">{placeholder}</option>
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <input
          type={type}
          {...register(name, validation)}
          className={`w-full p-2 border rounded ${
            errors[name] ? 'border-red-500' : 'border-gray-300'
          }`}
        />
      )}

      {inputInfoText && (
        <p className="text-gray-600 text-xs italic mt-1">{inputInfoText}</p>
      )}
    </div>
  );
};

export default Input;
