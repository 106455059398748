import { Fragment, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Input from "./Input";
let initialState = {};
const OtherDetails = (props) => {
  const { company, formFields } = props;
  const formRef = useForm(initialState);
  const {
    handleSubmit,
    setValue,
    formState: { isDirty, isValid },
  } = formRef;
  const partnerFields = formFields[company]?.filter(
    (field) => field?.page === "other-details"
  );
  const formValidationsObj = {};
  partnerFields?.forEach((field) => {
    formValidationsObj[field.name] = { ...field };
    initialState[field.name] = "";
  });

  const redirectToNextPage = handleSubmit((data) => {
    props?.redirectToStep(data);
  });

  return (
    <>
      <div className="text-2xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
        Other Details
      </div>
      <FormProvider {...formRef}>
        <form
          onSubmit={(e) => e.preventDefault()}
          noValidate
          autoComplete="off"
          className="my-5 px-5"
        >
          <div className="max-h-[500px] overflow-y-auto">
            {partnerFields?.map((element, index) => (
              <Fragment key={`${element.name}-${index}`}>
                {element?.name && element?.name !== "terms_conditions" && (
                  <Input {...element} />
                )}
              </Fragment>
            ))}
          </div>

          <div className="my-7 mx-5 flex items-center justify-center">

            <button
              onClick={redirectToNextPage}
              disabled={!isDirty || !isValid}
              className="bg-[#4E05FF] text-white text-base py-2 px-3 rounded disabled:opacity-50"
            >
              Continue
            </button>
          </div>
          <p className="text-sm text-gray-500">For any help please reach out to our support team on this number 022-41498149 </p>
          <p className="text-sm text-gray-500"> ಯಾವುದೇ ಸಹಾಯಕ್ಕಾಗಿ ದಯವಿಟ್ಟು ನಮ್ಮ ಸಹಾಯ ತಂಡವನ್ನು ಈ ಸಂಖ್ಯೆಯಲ್ಲಿ ಸಂಪರ್ಕಿಸಿ: 022-41498149 </p>
        </form>
      </FormProvider>
    </>
  );
};
export default OtherDetails;
